<template>
  <div>
    <!-- Header Section -->
    <div style="text-align: left; padding: 20px 0 20px 40px; background-color: #f4f5f6;">
      <img src="@/static/img/logo_white.png" alt="PLN Energi Primer Indonesia" style="width: 120px;" />
    </div>

    <!-- Main Content -->
    <Main>
      <a-row :gutter="25">
        <a-col :sm="24" :xs="24">
          <div style="padding: 20px; background-color: #fff; border: 1px solid #ddd; border-radius: 8px;">
            <h1 style="text-align: center;">Kebijakan Privasi</h1>
            <div v-if="loading">Loading...</div>
            <div v-else>
              <p v-html="privacyPolicy"></p>
            </div>

            <div style="text-align: center; margin-top: 20px;">
              <p>2024 © PT PLN Energi Primer Indonesia</p>
            </div>
          </div>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import axios from 'axios';
import { Main } from '../styled';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'PrivacyPolicy',
  components: {
    Main,
  },
  setup() {
    const loading = ref(true);
    const privacyPolicy = ref('');

    // Fetch kebijakan privasi dari backend
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/privacy-policy`);
        privacyPolicy.value = response.data?.data?.content || 'Kebijakan privasi tidak tersedia.';
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
        privacyPolicy.value = 'Terjadi kesalahan saat mengambil kebijakan privasi.';
      } finally {
        loading.value = false;
      }
    };

    // Ambil kebijakan privasi saat komponen dimount
    onMounted(() => {
      fetchPrivacyPolicy();
    });

    return {
      loading,
      privacyPolicy,
    };
  },
});
</script>

<style>
/* Styling untuk elemen dari kebijakan privasi */
section {
  /* margin: 20px 0; */
  /* padding: 15px; */
  /* background-color: #f9f9f9; */
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
}

h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
  margin-top: 20px;
}

h3 {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 8px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}

li {
  font-size: 1em;
  color: #666;
  margin: 5px 0;
}
</style>
